import { useEffect, useState } from 'react';

import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField } from '@mui/material';
import { useFormik, FormikProvider, Field } from 'formik';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { cpfMask, phoneMask, cepMask } from 'lib/helpers/masks';

import { IElements, IProps, IUser, IUserReceived } from './models';
import {
  FieldRow,
  FixedLabel,
  BlockTitle,
  BaseInputLabel,
  Button,
} from './style';
import { CompanyApi } from 'lib/api/company';
import { CompanyDTO } from 'lib/api/company.protocols';
import { SectorDTO } from 'lib/api/sector.protocols';
import { GlobalApi } from 'lib/api/global';
import { AddressDTO } from 'lib/api/global.protocols';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { UserApi } from 'lib/api/user';
import CircularProgress from '@mui/material/CircularProgress';
import { validateCPF } from 'lib/helpers/cpf-validation';

const UserForm = ({
  isEdit,
  setOpen,
  handleImage,
  imageUrl,
  setImageUrl,
  snackBar,
  user,
  companies,
  roles,
  currentId,
  getUsersPaginated,
  handleCloseModalForm,
}: IProps) => {
  const [selectedSectors, setSelectedSectors] = useState<SectorDTO[]>([]);
  const [isAnalist, setIsAnalist] = useState(false);
  const [findedAddress, setFindedAddress] = useState<AddressDTO>();
  const [inLoading, setInLoading] = useState(false);
  const [birthdate, setBirthdate] = useState<Dayjs | null>(
    user?.birthdate && isEdit ? dayjs(user?.birthdate) : null,
  );
  const [userSectors] = useState<any[]>([]);

  const sortedCompanies =
    companies &&
    companies.sort((a: IElements, b: IElements) => {
      const treateAName = a.name
        .replace(/[^a-zA-Z0-9]/g, '')
        .toLowerCase()
        .trim();
      const treateBName = b.name
        .replace(/[^a-zA-Z0-9]/g, '')
        .toLowerCase()
        .trim();
      return treateAName > treateBName ? 1 : -1;
    });

  const sortedSectors =
    selectedSectors &&
    selectedSectors.sort((a: IElements, b: IElements) => {
      const treateAName = a.name
        .replace(/[^a-zA-Z0-9]/g, '')
        .toLowerCase()
        .trim();
      const treateBName = b.name
        .replace(/[^a-zA-Z0-9]/g, '')
        .toLowerCase()
        .trim();
      return treateAName > treateBName ? 1 : -1;
    });

  useEffect(() => {
    if (isEdit && user?.role === 'ANALISTA' && user.sectors) {
      user?.sectors.map((sector) => {
        userSectors.push(sector.id);
      });
    }
  }, [isEdit]);

  function validationOfUserData<T>(data: T) {
    if (isEdit) {
      if (data !== undefined) {
        return data;
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  const handleChangeBirthdate = (newValue: Dayjs | null) => {
    setBirthdate(newValue);
  };

  const [initialAddress, setInitialAddress] = useState<any>([
    {
      street: validationOfUserData(user?.address?.street),
      neighborhood: validationOfUserData(user?.address?.neighborhood),
      city: validationOfUserData(user?.address?.city),
      state: validationOfUserData(user?.address?.state),
    },
  ]);

  const selectedUser: any = {
    id: validationOfUserData(user?.id),
    name: validationOfUserData(user?.name),
    email: validationOfUserData(user?.email),
    role:
      user?.role !== null ? validationOfUserData(user?.role).toLowerCase() : '',
    status: validationOfUserData(user?.status),
    cpf: validationOfUserData(user?.cpf),
    gender:
      user?.gender !== null
        ? validationOfUserData(user?.gender).toLowerCase()
        : '',
    phone: user?.phone !== null ? validationOfUserData(user?.phone) : '',
    phoneType:
      user?.phoneType !== null
        ? validationOfUserData(user?.phoneType).toLowerCase()
        : '',
    companyId:
      user?.company !== null ? validationOfUserData(user?.company?.id) : '',
    profilePicture: validationOfUserData(user?.profilePicture),
    address: {
      cep: validationOfUserData(user?.address?.cep),
      number: validationOfUserData(user?.address?.number),
      complement: validationOfUserData(user?.address?.complement),
    },
  };

  const addressRefresh = () => {
    setInitialAddress([
      {
        street: findedAddress?.street,
        neighborhood: findedAddress?.neighborhood,
        city: findedAddress?.city,
        state: findedAddress?.state,
      },
    ]);
  };

  useEffect(() => {
    if (findedAddress) {
      addressRefresh();
    }
  }, [findedAddress]);

  const formik = useFormik({
    initialValues: {
      name: selectedUser.name,
      cpf: selectedUser.cpf,
      gender: selectedUser.gender,

      phoneType: selectedUser.phoneType,
      phone: selectedUser.phone,
      email: selectedUser.email,

      cep: selectedUser.address.cep,
      number: selectedUser.address.number,
      complement: selectedUser.address.complement,

      role: selectedUser.role,
      companyId: selectedUser.companyId,
      sectorsIds: userSectors,
    },

    onSubmit: async (values) => {
      // eslint-disable-next-line prefer-const
      let sectorsToSend: string[] = [];

      values.sectorsIds.map((sector) => sectorsToSend.push(sector));

      const treatedCpf = values.cpf.replace(/\.|-|\s/g, '');

      if (treatedCpf.length > 0 && treatedCpf.length < 11) {
        snackBar('O CPF precisa conter 11 dígitos.', 'error');
        return;
      }
      if (values.cpf) {
        const cpfIsValid = validateCPF(values.cpf);

        if (!cpfIsValid) {
          snackBar('O CPF inserido não é válido.', 'error');
          return;
        }
      }

      if (values.role === 'analista' && values.sectorsIds.length == 0) {
        snackBar('Deve ser selecionado ao menos um setor.', 'error');
        return;
      }

      const dataToSend: IUser | IUserReceived = {
        name: values.name,
        email: values.email,
        role: values.role.toUpperCase(),
        status: 'ATIVO',
        cpf: values.cpf,
        birthdate: birthdate,
        gender: values.gender.toUpperCase(),
        phone: values.phone,
        phoneType: values.phoneType.toUpperCase(),
        companyId:
          values.role.toUpperCase() === 'ANALISTA' ? values.companyId : '',
        sectorsIds:
          sectorsToSend && values.role.toUpperCase() === 'ANALISTA'
            ? sectorsToSend
            : [],
        profilePicture: imageUrl,
        address: {
          cep: values.cep,
          street: initialAddress[0].street,
          number: values.number,
          complement: values.complement,
          neighborhood: initialAddress[0].neighborhood,
          city: initialAddress[0].city,
          state: initialAddress[0].state,
        },
      };

      setInLoading(true);

      if (isEdit) {
        try {
          await UserApi.editUser(currentId, dataToSend);
          setImageUrl('');
          snackBar('Usuário editado com sucesso!', 'success');
          getUsersPaginated();
          handleCloseModalForm();
          setInLoading(false);
        } catch (err: any) {
          setInLoading(false);
          if (
            err.response.data.message ===
            'Esse email já está sendo utilizado por um outro usuário.'
          ) {
            snackBar(
              'Este e-mail já está sendo utilizado, favor inserir outro e-mail.',
              'error',
            );
            return;
          } else {
            snackBar(
              'Ocorreu um erro ao tentar criar o usuário, favor entrar em contato com a equipe de suporte.',
              'error',
            );
          }
        }
        return;
      } else {
        try {
          await UserApi.createUser(dataToSend);
          setImageUrl('');
          snackBar('Usuário criado com sucesso!', 'success');
          getUsersPaginated();
          handleCloseModalForm();
          setInLoading(false);
        } catch (err: any) {
          setInLoading(false);
          if (err.response.data.message === 'This email is already in use') {
            snackBar(
              'Este e-mail já está sendo utilizado, favor inserir outro e-mail.',
              'error',
            );
            return;
          } else {
            snackBar(
              'Ocorreu um erro ao tentar criar o usuário, favor entrar em contato com a equipe de suporte.',
              'error',
            );
          }
        }
      }
    },
  });

  useEffect(() => {
    if (formik.values.role === 'Analista') {
      setIsAnalist(true);
    } else {
      setIsAnalist(false);
    }
  }, [formik.values.role]);

  const retrieveCompany = async () => {
    const response: CompanyDTO = await CompanyApi.getCompany(
      formik.values.companyId,
    );
    setSelectedSectors(response.sectors);
  };

  useEffect(() => {
    if (formik.values.companyId) {
      retrieveCompany();
    }
  }, [formik.values.companyId]);

  const retrieveAddress = async () => {
    if (formik.values.cep) {
      try {
        const response = await GlobalApi.getAddress(formik.values.cep);
        setFindedAddress(response);
      } catch {
        console.log('CEP inválido');
      }
    }
  };

  const renderCompaniesSelect = () => {
    if (!isEdit) {
      return (
        <FormControl id="userCompany" style={{ width: '330px' }}>
          <BaseInputLabel id="demo-simple-select-label">Empresa</BaseInputLabel>
          <Select
            required
            fullWidth
            name="companyId"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={formik.values.companyId}
            onChange={formik.handleChange}
          >
            {sortedCompanies?.map((item) => (
              <MenuItem value={item.id}>{item.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    } else {
      return (
        <FormControl id="userCompany" style={{ width: '330px' }}>
          <BaseInputLabel id="demo-simple-select-label">Empresa</BaseInputLabel>
          <Select
            disabled
            fullWidth
            name="companyId"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={formik.values.companyId}
            onChange={formik.handleChange}
          >
            {companies?.map((item) => (
              <MenuItem value={item.id}>{item.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }
  };

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        {!isEdit ? (
          <DialogTitle id="alert-dialog-title">{'NOVO USUÁRIO'}</DialogTitle>
        ) : (
          <DialogTitle id="alert-dialog-title">{'EDITAR USUÁRIO'}</DialogTitle>
        )}

        <BlockTitle>Dados pessoais</BlockTitle>

        <FieldRow>
          <TextField
            required
            fullWidth
            name="name"
            label="Nome completo"
            type="text"
            value={formik.values.name}
            onChange={formik.handleChange}
          />
        </FieldRow>

        <FieldRow>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label="Data de nascimento"
              format="DD/MM/YYYY"
              value={birthdate}
              onChange={handleChangeBirthdate}
            />
          </LocalizationProvider>

          <TextField
            name="cpf"
            label="CPF"
            type="text"
            value={formik.values.cpf ? cpfMask(formik.values.cpf) : ''}
            onChange={formik.handleChange}
            inputProps={{ maxLength: 14 }}
          />
        </FieldRow>

        <FieldRow>
          <FormControl fullWidth>
            <BaseInputLabel id="demo-simple-select-label">Sexo</BaseInputLabel>
            <Select
              fullWidth
              name="gender"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={formik.values.gender}
              onChange={formik.handleChange}
            >
              <MenuItem value="masculino">Masculino</MenuItem>
              <MenuItem value="feminino">Feminino</MenuItem>
            </Select>
          </FormControl>
        </FieldRow>

        <BlockTitle>Informações de contato</BlockTitle>

        <FieldRow>
          <FormControl style={{ width: '425px' }}>
            <BaseInputLabel id="demo-simple-select-label">
              Tipo de contato
            </BaseInputLabel>
            <Select
              fullWidth
              name="phoneType"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={formik.values.phoneType}
              onChange={formik.handleChange}
            >
              <MenuItem value="celular">Celular</MenuItem>
              <MenuItem value="residencial">Residencial</MenuItem>
            </Select>
          </FormControl>

          <TextField
            name="phone"
            label="Número de telefone"
            type="text"
            value={phoneMask(formik.values.phone, formik.values.phoneType)}
            onChange={formik.handleChange}
            inputProps={{
              maxLength: formik.values.phoneType === 'celular' ? 15 : 14,
            }}
          />
        </FieldRow>

        <FieldRow>
          <TextField
              required
              fullWidth
              name="email"
              label="E-mail"
              type="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
          />
          {/*{!isEdit ? (*/}
          {/*  <TextField*/}
          {/*    required*/}
          {/*    fullWidth*/}
          {/*    name="email"*/}
          {/*    label="E-mail"*/}
          {/*    type="email"*/}
          {/*    value={formik.values.email}*/}
          {/*    onChange={formik.handleChange}*/}
          {/*    error={formik.touched.email && Boolean(formik.errors.email)}*/}
          {/*    helperText={formik.touched.email && formik.errors.email}*/}
          {/*  />*/}
          {/*) : (*/}
          {/*  <TextField*/}
          {/*    disabled*/}
          {/*    fullWidth*/}
          {/*    name="email"*/}
          {/*    label="E-mail"*/}
          {/*    type="email"*/}
          {/*    value={formik.values.email}*/}
          {/*  />*/}
          {/*)}*/}
        </FieldRow>

        <BlockTitle>Endereço</BlockTitle>

        <FieldRow>
          <TextField
            name="cep"
            label="CEP"
            type="text"
            value={cepMask(formik.values.cep)}
            onChange={formik.handleChange}
            onBlur={() => retrieveAddress()}
          />

          <TextField
            name="state"
            label="Estado"
            type="text"
            value={initialAddress[0].state}
            onChange={(event) =>
              setInitialAddress((prev: any) => [
                { ...prev, state: event.target.value },
              ])
            }
          />
        </FieldRow>

        <FieldRow>
          <TextField
            fullWidth
            name="street"
            label="Rua / Avenida"
            type="text"
            value={initialAddress[0].street}
            onChange={(event) =>
              setInitialAddress((prev: any) => [
                { ...prev, street: event.target.value },
              ])
            }
          />
        </FieldRow>

        <FieldRow>
          <TextField
            fullWidth
            name="number"
            label="Número"
            type="number"
            value={formik.values.number}
            onChange={formik.handleChange}
          />

          <TextField
            fullWidth
            name="complement"
            label="Complemento"
            type="text"
            value={formik.values.complement}
            onChange={formik.handleChange}
          />
        </FieldRow>

        <FieldRow>
          <TextField
            fullWidth
            name="neighborhood"
            label="Bairro"
            type="text"
            value={initialAddress[0].neighborhood}
            onChange={(event) =>
              setInitialAddress((prev: any) => [
                { ...prev, neighborhood: event.target.value },
              ])
            }
          />

          <TextField
            fullWidth
            name="city"
            label="Cidade"
            type="text"
            value={initialAddress[0].city}
            onChange={(event) =>
              setInitialAddress((prev: any) => [
                { ...prev, city: event.target.value },
              ])
            }
          />
        </FieldRow>

        <BlockTitle>Dados de acesso</BlockTitle>

        <FieldRow>
          <FormControl style={{ width: '330px' }}>
            <BaseInputLabel id="demo-simple-select-label">
              Categoria de usuário *
            </BaseInputLabel>
            {!isEdit ? (
              <Select
                required
                name="role"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formik.values.role}
                onChange={formik.handleChange}
              >
                {roles?.map((item) => (
                  <MenuItem value={item.toLowerCase()}>{item}</MenuItem>
                ))}
              </Select>
            ) : (
              <Select
                disabled
                name="role"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formik.values.role}
              >
                {roles?.map((item) => (
                  <MenuItem value={item.toLowerCase()}>{item}</MenuItem>
                ))}
              </Select>
            )}
          </FormControl>

          {isAnalist ||
            (formik.values.role === 'analista' && renderCompaniesSelect())}
        </FieldRow>

        {formik.values.role === 'analista' && (
          <FieldRow
            style={{
              flexDirection: 'column',
              gap: '10px',
              marginBottom: '5px',
            }}
          >
            <div id="checkbox-group">Setores</div>
            <div
              className="sectorsSelects"
              role="group"
              aria-labelledby="checkbox-group"
            >
              {sortedSectors.map((item) => {
                return (
                  <label>
                    <Field type="checkbox" name="sectorsIds" value={item.id} />
                    {item.name}
                  </label>
                );
              })}
            </div>
          </FieldRow>
        )}

        {!isEdit ? (
          <FieldRow>
            <FixedLabel>Foto de perfil</FixedLabel>
            <TextField
              type="file"
              onChange={(e: any) => {
                handleImage(e);
              }}
            />
          </FieldRow>
        ) : (
          <FieldRow>
            <FixedLabel>Foto de perfil</FixedLabel>
            <TextField
              type="file"
              onChange={(e: any) => {
                handleImage(e);
              }}
            />
          </FieldRow>
        )}

        <DialogActions>
          <Button
            variant="outlined"
            color="error"
            onClick={() => setOpen(false)}
          >
            Cancelar
          </Button>
          {inLoading ? (
            <Button
              variant="contained"
              color="success"
              startIcon={<CircularProgress color="inherit" />}
              autoFocus
              type="submit"
            >
              Salvar
            </Button>
          ) : (
            <Button variant="contained" color="success" autoFocus type="submit">
              Salvar
            </Button>
          )}
        </DialogActions>
      </form>
    </FormikProvider>
  );
};

export { UserForm };
